/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import AffiliateOverview from '@components/shared/AffiliateOverview';
import {
    Button,
    ColumnWrap,
    CustomColumn,
    PaymentPageWrap,
    PolicyWrap,
} from '@components/shared/View';

import { useGlobalProps } from '@hooks/useGlobalProps';
import {
    STATUS_CODE,
} from '@constants/api';
import AlertModal from '@components/shared/modals/AlertModal';
import {
    displayAmountWithDecimalPlace,
} from '@utils/util';
import { getKEWithdrawlV2 } from '@api/payment';
import ConfirmModal from '@components/shared/modals/ConfirmModal';
import { FONT_SIZE } from '@constants/numbers';
import { getWPolicy } from '@api/other';
import { Col, Row } from 'antd';
import { getBankOutList } from '@api/other';
import { Spin } from 'antd';
import AmountColumn from '@components/shared/AmountColumn';
import MobileVerifyModal from '@components/shared/modals/MobileVerifyModal';
import { Form, Input } from 'antd';
import { FaMobile } from 'react-icons/fa';
import { displayAmountWithPrecision } from '@utils/util';
import { getUserInfo } from '@api/user';

const PHONE_DEFAULT_TELHD = '01';
const PHONE_DEFAULT_MIN = 0;
const PHONE_DEFAULT_MAX = 13;

const INIT_ALERT = {
    show: false,
    title: '',
};
export default function WithdrawalKEPage() {
    const [amount, setAmount] = useState('');
    const [mobile, setMobile] = useState('');
    const [showMobileVerifyModal, setShowMobileVerifyModal] = useState(false);


    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [errorAlert, setErrorAlert] = useState({ ...INIT_ALERT });
    const [policy, setPolicy] = useState(null);
    // const [messageApi, contextHolder] = message.useMessage();

    const {
        user,
        userData,
        balance,
        decimalPlace,
        withdrawAmountOptions,
        mainUnit,
        mainUnitRaw,
        secondUnitRaw,
        unitRate,
        getBalance,
        pixelCodes,
        withdrawStepperOptions
    } = useGlobalProps();

    const { i18n, t } = useTranslation();
    const tCommon = t('common', { returnObjects: true });
    const tPayment = t('payment', { returnObjects: true });
    const tUser = t('user', { returnObjects: true });
    const tError = t('error', { returnObjects: true });
    const [amountInfos, setAmountInfos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const formRef = useRef(null);

    const hintPhone = tUser['hint@phone']
        ? tUser['hint@phone']
            .replace('{telhd}', user?.telhd || PHONE_DEFAULT_TELHD)
            .replace('{minln}', user?.minln || PHONE_DEFAULT_MIN)
            .replace('{maxln}', user?.maxln || PHONE_DEFAULT_MAX)
        : '';

    useEffect(() => {
        if (Object.keys(withdrawAmountOptions).length > 0) {
            const infos = [];
            if (withdrawAmountOptions.amounts) {
                for (const amount of withdrawAmountOptions.amounts) {
                    const info = {
                        amount,
                        currency: withdrawAmountOptions.currency,
                        pointUnit: withdrawAmountOptions.pointUnit,
                        points: withdrawAmountOptions.rate
                            ? amount * parseFloat(withdrawAmountOptions.rate)
                            : 0,
                        rate: withdrawAmountOptions.rate
                            ? parseFloat(withdrawAmountOptions.rate)
                            : 0,
                    };
                    infos.push(info);
                }
            }
            setAmountInfos(infos);
        }
    }, [withdrawAmountOptions]);



    const getPolicy = async () => {
        const res = await getWPolicy(i18n.language);
        if (res && res[0]) setPolicy(res[0]);
        else setPolicy(null);
    };

    const handleInputChange = async (e) => {
        const targetValue = e.target.value;
        const name = e.target.name;
        if (name === 'amount') {
            if (balance?.bln && targetValue > balance.bln)
                setAmount(balance.bln);
            else setAmount(targetValue);
        }
    };



    useEffect(() => {
        getBankOutList().then((res) => {
            if (res?.rtn === STATUS_CODE.SUCCESS && res?.bank_list) {
                // setBankoutList(res.bank_list);
            }
        });
    }, []);



    const getFee = () => {
        if (
            !amount ||
            !balance?.out_fee_per
        )
            return 0;
        try {
            const basicAmount = parseInt(amount);
            return displayAmountWithDecimalPlace(
                basicAmount * (balance?.out_fee_per / 100),
                decimalPlace
            );
        } catch (e) {
            return 0;
        }
    };


    const validatorPhone = (_rule, value) => {
        if (!value) return Promise.reject();
        const trimmedValue = value.trim();

        const validLen = !(
            trimmedValue.length < user.minln || trimmedValue.length > user.maxln
        );
        if (user?.telhd && user?.minln && user?.maxln) {
            const telhds = user?.telhd.split(',');
            let isValid = false;
            telhds.forEach((e) => {
                if (!isValid && trimmedValue.startsWith(e) && validLen) {
                    isValid = true;
                }
            });
            if (!!isValid) return Promise.resolve();
        }

        return Promise.reject();
    };

    useEffect(() => {
        if (user?.ukey) getBalance();
    }, [user, getBalance]);


    useEffect(() => {
        getPolicy();
    }, [i18n.language]);

    useEffect(() => {
        // if (user && user.last_tel) {
        //     setMobile(user.last_tel);
        //     formRef.current.setFieldsValue({
        //         mobile: user.last_tel
        //     });
        // } else 
        if (userData && userData?.tel) {
            setMobile(userData.tel);
            formRef.current.setFieldsValue({
                mobile: userData.tel
            });
        }

    }, [userData, user]);

    const handleSelectAmount = (val) => {
        setAmount((prev) => {
            if (withdrawStepperOptions && withdrawStepperOptions.display && parseFloat(val) > 0 && prev !== '') {
                if (user?.ko_max > 0 && (parseFloat(prev) + parseFloat(val) > user?.ko_max)) {
                    return user?.ko_max;
                };
                if (balance?.out_min > 0 && ((parseFloat(prev) || 0) + parseFloat(val) < balance?.out_min)) {
                    return balance?.out_min;
                };
                if (balance?.bln && (parseFloat(prev) + parseFloat(val) > balance?.bln)) {
                    return balance.bln;
                }

                return (parseFloat(prev) || 0) + parseFloat(val);

            } else {

                if (val === 0) return '';

                if (user?.ko_max > 0 && (parseFloat(val) > user?.ko_max)) {
                    return user?.ko_max;
                };
                if (balance?.out_min > 0 && (parseFloat(val) < balance?.out_min)) {
                    return balance?.out_min;
                };

                if (balance?.bln && (parseFloat(val) > balance?.bln)) {
                    return balance.bln;
                }

                return val;
            }
        });
    };

    const doWithdrawal = async () => {
        if (isLoading) return;
        setIsLoading(true);





        let ukey = user.ukey;

        const userInfoRes = await getUserInfo({ dvc: user?.device_id });



        if (userInfoRes.ukey) {
            ukey = userInfoRes.ukey;
        }


        const balanceRes = await getBalance();

        if (!balanceRes || amount > balanceRes?.bln) {
            setErrorAlert({
                show: true,
                title: tCommon['common@failed'],
                message:
                    tError[
                    'error@deposit_insufficient_amount'
                    ],
            });
            setIsLoading(false);
            return;
        }

        const req = {
            ukey: ukey,
            state: 2,
            val: amount,
            tel: mobile,
            acc: user.acc,
            bln: balanceRes?.bln
            // bank: 'MPESA'
        }



        const res = await getKEWithdrawlV2(req);

        setIsLoading(false);
        setShowConfirmModal(false);


        if (!res || typeof res !== 'object') {

            setErrorAlert({
                show: true,
                title: tCommon['common@failed'],
                // message: parseErrorRes(tError, res),
                message: tError['error@server_error'],
            });

            setIsLoading(false);

            return;
        }
        if (res['ret code'] !== "0" && res['ret code'] !== "00") {
            setErrorAlert({
                show: true,
                title: tCommon['common@failed'],
                message: res['ret msg'],
            });
            setIsLoading(false);

            return;
        }



        setErrorAlert({
            show: true,
            title: tCommon['common@success'],
            message: tPayment['payment@withdrawl-successfully'],
        });

        // let data;

        // console.log(' RES _>  ', res)

        // if (res && res.data) {
        //     try {
        //         data = JSON.parse(res.data);
        //     } catch (error) {
        //         data = {
        //             state: false,
        //             info: 'cannot parse RES',
        //         };
        //     }
        // } else {
        //     data = {
        //         state: false,
        //         info: tError['error@cannot_get_bank_info'],
        //     };
        // }


        setAmount('');


    };



    const canSubmit = amount >= balance?.out_min;
    console.log(' balance ->  ', balance);
    const outOfBalance = amount > balance?.bln;

    const feeText = tPayment['payment@withdrawal-fee-msg']
        ?.replace('{percent}', balance?.out_fee_per)
        ?.replace('{fee}', getFee());
    const displayPolicy = policy && policy.content?.length > 0;
    return (
        <PaymentPageWrap>
            {/* {contextHolder} */}
            <AffiliateOverview />
            <ColumnWrap className="mt-20 mb-1 pl-3" alignItems="start">

                <CustomColumn
                    title={tUser['user@phone']}
                    child={
                        <Form ref={formRef}>
                            <Form.Item
                                name={'mobile'}
                                rules={[
                                    {
                                        required: true,
                                        message: hintPhone,
                                        min: user?.minln || PHONE_DEFAULT_MIN,
                                        max: user?.maxln || PHONE_DEFAULT_MAX,
                                        whitespace: false,
                                        validator: validatorPhone,
                                    },
                                ]}
                                // help={hintPhone}
                                initialValue={mobile}
                                className={`register-form-mobile`}
                            >
                                <Input
                                    name={'mobile'}
                                    type={
                                        'text'
                                    }
                                    addonBefore={<FaMobile />}
                                    maxLength={
                                        ''
                                    }
                                    value={mobile}
                                    onChange={handleInputChange}
                                    readOnly={true}
                                />
                            </Form.Item>
                        </Form>
                    }
                />

                <AmountColumn
                    title={tPayment['payment@withdrawal-amount']}
                    mainUnit={mainUnit}
                    displayAmountOptions={withdrawAmountOptions && withdrawAmountOptions.display}
                    amount={amount}
                    onAmountChange={handleInputChange}
                    onSelecteAmount={handleSelectAmount}
                    amountInfos={amountInfos}
                    displayStepper={withdrawStepperOptions && withdrawStepperOptions.display}
                />

                {balance && (
                    <CustomColumn
                        notRequired
                        child={
                            <Row justify="space-between">
                                <Col>
                                    <WithdrawalFeeInfo>
                                        <p>
                                            {balance?.out_min > 0 && !user?.ko_max && `${tPayment['payment@withdrawal-min-amount']}: ${displayAmountWithPrecision(
                                                balance?.out_min
                                            )}${mainUnit && ` ${mainUnit}`
                                                }`}
                                            {!balance?.out_min && user?.ko_max > 0 && `${tPayment['payment@withdrawal-max-amount']}: ${displayAmountWithPrecision(
                                                user?.ko_max
                                            )}${mainUnit && ` ${mainUnit}`
                                                }`}
                                            {balance?.out_min > 0 && user?.ko_max > 0 && `${tPayment['payment@withdrawal-amount-limit']
                                                }: ${displayAmountWithPrecision(
                                                    balance?.out_min
                                                )} ~ ${displayAmountWithPrecision(
                                                    user?.ko_max
                                                )}${mainUnit && ` ${mainUnit}`
                                                }`}
                                        </p>
                                        {balance?.out_fee_per > 0 && (
                                            <p>{feeText}</p>
                                        )}
                                    </WithdrawalFeeInfo>
                                </Col>
                            </Row>
                        }
                    />
                )}

                <CustomColumn
                    child={
                        <Button
                            onClick={() => {
                                if (!canSubmit) {
                                    setErrorAlert({
                                        show: true,
                                        title: tCommon['common@failed'],
                                        message:
                                            tError[
                                            'error@deposit_insufficient_amount'
                                            ],
                                    });
                                    return;
                                }
                                if (outOfBalance) {
                                    setErrorAlert({
                                        show: true,
                                        title: tCommon['common@failed'],
                                        message:
                                            tError[
                                            'error@deposit_insufficient_balance'
                                            ],
                                    });
                                    return;
                                }

                                if (pixelCodes && pixelCodes.length > 0) {
                                    pixelCodes.forEach((code) => window.fbq('trackSingleCustom', code, 'WithdrawlSubmission'));
                                }

                                setShowConfirmModal(true);
                            }}
                        >
                            {tCommon['common@submit']}
                        </Button>
                    }
                    notRequired
                />
            </ColumnWrap>
            {displayPolicy && (
                <PolicyWrap>
                    <div dangerouslySetInnerHTML={{ __html: policy.content }} />
                </PolicyWrap>
            )}
            <ConfirmModal
                show={showConfirmModal}
                message={tPayment['payment@confirm-withdrawal']}
                children={
                    <>
                        {isLoading ? (
                            <SpinContainer>
                                <Spin
                                    style={{
                                        top: '50%',
                                        left: '50%',
                                        position: 'absolute',
                                    }}
                                />
                            </SpinContainer>
                        ) : (
                            <WithdrawalFeeInfo>
                                <p className="text-center">{`${tPayment['payment@withdrawal-amount']}: ${amount} ${mainUnitRaw && ` ${mainUnitRaw}`
                                    }${secondUnitRaw && unitRate && ` (${amount * parseFloat(unitRate)} ${secondUnitRaw})`
                                    }`}</p>
                                {balance?.out_fee_per > 0 && (
                                    <p className="text-center">{feeText}</p>
                                )}
                            </WithdrawalFeeInfo>
                        )}
                    </>
                }
                onConfirm={doWithdrawal}
                onCancel={() => setShowConfirmModal(false)}
            />
            <MobileVerifyModal
                show={showMobileVerifyModal}
                message={tPayment['payment@change-mobile']}
                onConfirm={(phone) => {
                    setMobile(phone);
                    setShowMobileVerifyModal(false)
                }}
                onCancel={() => setShowMobileVerifyModal(false)}
            />
            <AlertModal
                {...errorAlert}
                onConfirm={() => setErrorAlert({ ...INIT_ALERT })}
            />
        </PaymentPageWrap>
    );
}

// const MobileInfo = styled.div`

//     display: flex;
//     align-items: center;
//     > span {
//         color: black;   
//         margin-right: 8px;
//         font-size: ${FONT_SIZE.NORMAL};
//         font-weight: 800;
//     }
// `;

const WithdrawalFeeInfo = styled.div`
    color: black;
    > p {
        margin: 0;
        margin-top: 5px;
        font-size: ${FONT_SIZE.ALERT_MODAL_TITLE};
        font-weight: 800;
    }
    .text-center {
        text-align: center;
    }
`;

const SpinContainer = styled.div`
    textalign: center;
    position: relative;
    width: 100%;
    height: 80px;
`;
