import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ModalContainer } from '@components/shared/modals/BaseModal';
import SidebarMenuProvider from '@hooks/useSidebarMenu';
import GlobalStyles from '@/theme/GlobalStyles';
import ROUTES from '@constants/routes';
import LiveChatPage from '@routes/LiveChatPage';
import Root from '@routes/Root';
import ErrorPage from '@routes/ErrorPage';
import HomePage from '@routes/HomePage';
import PromosPage from '@routes/PromosPage';
import MaintenancePage from '@routes/MaintenancePage';
import RegisterPage from '@routes/RegisterPage';
import GamePage from '@routes/GamePage';

// import MarqueeView from '@components/shared/MarqueeView';
// import LiveChatFloating from '@components/shared/LiveChatFloating';
import './i18n';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import GlobalThemeProvider from '@components/shared/GlobalThemeProvider';
// import AddToHomeScreen from '@components/shared/AddToHomeScreen';
// import AddToHomeFloatButton from '@components/shared/AddToHomeFloatButton';
import DetectSplash from '@components/shared/DetectSplash';
import PasswordPage from '@routes/PasswordPage';
import GlobalPropsProvider from '@hooks/useGlobalProps';
import LogoutPage from '@routes/LogoutPage';
import DepositPage from '@routes/DepositPage';
import WithdrawalPage from '@routes/WithdrawalPage';
import TransferPage from '@routes/TransferPage';
import BankAccountPage from '@routes/BankAccountPage';
import TransactionLogPage from '@routes/TransactionLogPage';
import BetLogPage from '@routes/BetLogPage';
import AffiliateLogPage from '@routes/AffiliateLogPage';
import CustomWebsiteHead from '@components/shared/CustomWebsiteHead';
import { Helmet } from 'react-helmet';
import {
    META_TITLE,
    META_DESCRIPTION,
    IS_GW,
    IS_AW_VN,
    IS_AW_KE,
    IS_BITSBET,
} from '@constants/config';
import DepositBankingPage from '@routes/DepositBankingPage';
import WithdrawBankingPage from '@routes/WithdrawBankingPage';
import { QRCodeProvider } from '@hooks/useQRCode';
// import { IS_AW_VN, IS_KH } from '@constants/config';
// import DepositVNPage from '@routes/DepositVNPage';
import DepositVNV3Page from '@routes/DepositVNV3Page';

import WithdrawalVNV3Page from '@routes/WithdrawalVNV3Page';
import DepositAWGeneralPage from '@routes/DepositAWGeneralPage';
import WithdrawalAWGeneralPage from '@routes/WithdrawalAWGeneralPage';
import DepositKEPage from '@routes/DepositKEPage';
import WithdrawalKEPage from '@routes/WithdrawalKEPage';

// import {
//     legacyLogicalPropertiesTransformer,
//     StyleProvider,
// } from '@ant-design/cssinjs';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'antd/dist/reset.css';

const getDepositPage = () => {
    if (IS_AW_VN) return <DepositVNV3Page />;
    if (IS_GW) return <DepositPage />;
    if (IS_AW_KE || IS_BITSBET) return <DepositKEPage />;
    return <DepositAWGeneralPage />;
};

const getWithdrawlPage = () => {
    if (IS_AW_VN) return <WithdrawalVNV3Page />;
    if (IS_GW) return <WithdrawalPage />;
    if (IS_AW_KE || IS_BITSBET) return <WithdrawalKEPage />;

    return <WithdrawalAWGeneralPage />;
};

const rootPath = (route) => {
    return {
        path: route,
        element: <Root />,
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: <HomePage />,
            },
            {
                path: ROUTES.PROMOS,
                element: <PromosPage />,
            },
            {
                path: ROUTES.PROFILE,
                element: <PasswordPage />,
            },
            {
                path: ROUTES.DEPOSIT,
                element: getDepositPage(),
            },
            {
                path: ROUTES.WITHDRAWAL,
                element: getWithdrawlPage(),
            },
            {
                path: ROUTES.TRANSFER,
                element: <TransferPage />,
            },
            {
                path: ROUTES.BANKACCOUNT,
                element: <BankAccountPage />,
            },
            {
                path: ROUTES.TRANSATION_LOG,
                element: <TransactionLogPage />,
            },
            {
                path: ROUTES.BET_LOG,
                element: <BetLogPage />,
            },
            {
                path: ROUTES.AFFILIATE_LOG,
                element: <AffiliateLogPage />,
            },
            {
                path: ROUTES.LOGOUT,
                element: <LogoutPage />,
            },
            {
                path: ROUTES.GAME,
                element: <GamePage />,
            },
            {
                path: ROUTES.DEPOSIT_BANKING,
                element: <DepositBankingPage />,
            },
            {
                path: ROUTES.WITHDRAWAL_BANKING,
                element: <WithdrawBankingPage />,
            },
        ],
    };
};

const router = createBrowserRouter([
    {
        path: ROUTES.LIVE_CHAT,
        element: <LiveChatPage />,
    },
    {
        path: ROUTES.MAINTENANCE,
        element: <MaintenancePage />,
    },
    {
        path: ROUTES.REGISTER,
        element: <RegisterPage />,
    },
    rootPath(ROUTES.ROOT),
    rootPath(ROUTES.ROOT_WITH_REF),
]);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    <Suspense>
        <GlobalPropsProvider>
            <Helmet>
                <title>{META_TITLE}</title>
                <meta name="description" content={META_DESCRIPTION} />
                <meta property="og:title" content={META_TITLE} />
                <meta property="og:description" content={META_DESCRIPTION} />
            </Helmet>
            {/* <StyleProvider transformers={[legacyLogicalPropertiesTransformer]}> */}
            <GlobalThemeProvider>
                <QRCodeProvider>
                    <SidebarMenuProvider>
                        <DetectSplash />
                        <CustomWebsiteHead />
                        <GlobalStyles />
                        <RouterProvider router={router} />
                        {/* <LiveChatFloating /> */}
                        {/* <AddToHomeScreen /> */}
                        {/* <AddToHomeFloatButton /> */}
                        <ModalContainer />
                    </SidebarMenuProvider>
                </QRCodeProvider>
            </GlobalThemeProvider>
            {/* </StyleProvider> */}
        </GlobalPropsProvider>
    </Suspense>
    // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
console.log(' serviceWorkerRegistration registered ')

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
