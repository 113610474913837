/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import AffiliateOverview from '@components/shared/AffiliateOverview';
import {
    Button,
    Column,
    ColumnWrap,
    CustomColumn,
    PaymentPageWrap,
    PolicyWrap,
} from '@components/shared/View';
import { useGlobalProps } from '@hooks/useGlobalProps';
import AlertModal from '@components/shared/modals/AlertModal';
import { displayAmountWithDecimalPlace } from '@utils/util';
import { postGWWithdrawalV2, getGWBankListV2 } from '@api/payment';


import ConfirmModal from '@components/shared/modals/ConfirmModal';
import { FONT_SIZE } from '@constants/numbers';
import { getWPolicy } from '@api/other';
// import BankTable, { parseBankRes } from '@components/bankAccount/BankTable';
import { IS_KH } from '@constants/config';
import { Col, Row } from 'antd';
import { displayAmountWithPrecision } from '@utils/util';
import {
    getUserInfo,
    getUserData,
    newUserBank,
    setUserBank,
    updateUserBank,
} from '@api/user'; import {
    STATUS_CODE,
    ADD_BANK_STATUS_CODE,
} from '@constants/api';

import BankAccountModal from '@components/shared/modals/BankAccountModal';
// import { getBankOutList } from '@api/other';
import { parseErrorRes } from '@utils/parseErrors';
import SingleBankTable, {
    parseBankRes,
} from '@components/bankAccount/SingleBankTable';

const INIT_ALERT = {
    show: false,
    title: '',
};
export default function WithdrawalPage() {

    const [amount, setAmount] = useState('');
    const [defaultBank, setDefaultBank] = useState(null);
    const [addBankModalInfo, setAddBankModalInfo] = useState({
        display: false,
        item: null,
    });

    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [errorAlert, setErrorAlert] = useState({ ...INIT_ALERT });
    const [policy, setPolicy] = useState(null);
    const { user, userData, balance, decimalPlace, mainUnit, mainUnitRaw, getBalance, pixelCodes, setUserData } = useGlobalProps();
    const { i18n, t } = useTranslation();
    const tCommon = t('common', { returnObjects: true });
    const tPayment = t('payment', { returnObjects: true });
    const tBank = t('bank', { returnObjects: true });
    const tError = t('error', { returnObjects: true });
    const [bankoutList, setBankoutList] = useState([]);

    // const [messageApi, contextHolder] = message.useMessage();

    const getPolicy = async () => {
        const res = await getWPolicy(i18n.language);
        if (res && res[0]) setPolicy(res[0]);
        else setPolicy(null);
    };
    const handleInputChange = async (e) => {
        const targetValue = e.target.value;
        const name = e.target.name;
        if (name === 'amount') {
            if (balance?.bln && targetValue > balance.bln)
                setAmount(balance.bln);
            else setAmount(targetValue);
        }
    };

    const getFee = () => {
        if (
            !amount ||
            !balance?.out_fee_per
        )
            return 0;
        try {
            const basicAmount = parseInt(amount);
            return displayAmountWithDecimalPlace(
                basicAmount * (balance?.out_fee_per / 100),
                decimalPlace
            );
        } catch (e) {
            return 0;
        }
    };

    useEffect(() => {
        if (user?.ukey) getBalance();
    }, [user, getBalance]);

    useEffect(() => {
        if (userData && userData?.acc_list) {
            const bank = userData?.acc_list.filter((b) => b[5] === true)?.[0];
            if (bank && bank.length > 0) {
                setDefaultBank(parseBankRes([bank]));
            } else {
                // setAddBankModalInfo({ display: true, item: null });
            }
        }
    }, [userData]);

    useEffect(() => {
        getPolicy();
    }, [i18n.language]);

    const getBankaccounts = async () => {
        if (user?.ukey) {
            const res = await getUserData({ ukey: user?.ukey });
            if (
                res.rtn === STATUS_CODE.SUCCESS &&
                res?.acc_list &&
                res.acc_list.length > 0
            ) {
                setUserData(res);
                const bank = res.acc_list.filter((b) => b[5] === true)?.[0];

                if (bank.length > 0) {
                    setDefaultBank(parseBankRes([bank]));
                }
            }
        }
    };


    const onToggleAddBankModal = () => {
        setAddBankModalInfo({
            ...addBankModalInfo,
            display: !addBankModalInfo.display,
        });
    };

    useEffect(() => {

        getGWBankListV2().then((res) => {

            console.log(' res -> ', res);
            if (res?.data) {
                const bankList = res.data.map((item) => [item.bankCode, item.bank]);
                setBankoutList(bankList);
            } else {
                setBankoutList([

                ]);
            }

        });
        // getBankOutList().then((res) => {
        //     if (res?.rtn === STATUS_CODE.SUCCESS && res?.bank_list) {
        //         setBankoutList(res.bank_list);
        //     } else
        //         setBankoutList([

        //         ]);
        // });
    }, []);

    const onChangeAccount = (item) => {
        setAddBankModalInfo({ display: true, item });
    };

    const addBankaccount = async (req) => {
        if (req.id) {
            const res = await updateUserBank({
                idx: req.id,
                ukey: user.ukey,
                bank: req.bank,
                banknm: req.fullName,
                bankacc: req.account,
            });
            if (res?.rtn === ADD_BANK_STATUS_CODE.SUCCESS) {
                setAddBankModalInfo({ display: false, item: null });
                getBankaccounts();
            } else {
                setErrorAlert({
                    show: true,
                    title: tCommon['common@failed'],
                    message: parseErrorRes(tError, res),
                });
            }
            return;
        }
        const res = await newUserBank({
            ukey: user.ukey,
            bank: req.bank,
            banknm: req.fullName,
            bankacc: req.account,
        });
        if (
            res?.rtn === ADD_BANK_STATUS_CODE.SUCCESS ||
            res?.rtn === ADD_BANK_STATUS_CODE.ACCOUNT_EXIST
        ) {
            const bindRes = await setUserBank({
                ukey: user.ukey,
                bank: req.bank,
                bankacc: req.account,
            });

            if (bindRes?.rtn !== STATUS_CODE.SUCCESS) {
                setErrorAlert({
                    show: true,
                    title: tCommon['common@failed'],
                    message: parseErrorRes(tError, res),
                });
            } else {
                setAddBankModalInfo({ display: false, item: null });
                getBankaccounts();
            }
        } else {
            setErrorAlert({
                show: true,
                title: tCommon['common@failed'],
                message: parseErrorRes(tError, res),
            });
        }
    };


    const doWithdrawal = async () => {

        setShowConfirmModal(false);


        const [{ bankcode, account, name }] = defaultBank;
        console.log(' defaultBank 3 -> ', defaultBank);


        let ukey = user.ukey;

        const userInfoRes = await getUserInfo({ dvc: user?.device_id });

        console.log(' ukuserInfoReseyRes -> ', userInfoRes);

        if (userInfoRes.ukey) {
            ukey = userInfoRes.ukey;
        }



        const res = await postGWWithdrawalV2({
            amount: parseFloat(amount),
            ukey: ukey,
            acc: user.acc,
            fee: Number(getFee()),
            customerBankCode: bankcode,
            customerBankAccount: account,
            customerBankHolderName: name
        });

        if (res?.result && res?.result.success && res?.result.code === '000000') {
            setErrorAlert({
                show: true,
                title: tCommon['common@success'],
                message: tPayment['payment@withdrawl-successfully'],
            });
            setAmount('');
        } else {
            // newTab.close();

            let message = tError['error@deposit'];
            if (res.result && res.result.code && res.result.message) {
                message = `${res.result.message}. ${tError['error@error-code']} : ${res.result.code}`;

            }

            setErrorAlert({
                show: true,
                title: tCommon['common@failed'],
                message: message,
            });
        }
    };

    const canSubmit = defaultBank && amount >= balance?.out_min;
    const feeText = tPayment['payment@withdrawal-fee-msg']
        ?.replace('{percent}', balance?.out_fee_per)
        ?.replace('{fee}', getFee());
    const displayPolicy = policy && policy.content?.length > 0;
    return (
        <PaymentPageWrap>
            {/* {contextHolder} */}
            <AffiliateOverview />
            <SingleBankTable
                dataSource={defaultBank}
                tBank={tBank}
                onChangeAccount={onChangeAccount}
                onCreateAccount={onToggleAddBankModal}
                displayFullName={true}
            // emptyView={
            //     <Button onClick={onToggleAddBankModal}>
            //         {tBank['bank@add-bank']}
            //     </Button>
            // }
            />

            {/* {!IS_KH && <BankTable dataSource={defaultBank} tBank={tBank} />} */}

            <ColumnWrap className="mt-20 mb-1 pl-3">
                <Column
                    title={`${tPayment['payment@withdrawal-amount']}${mainUnit && ` ${mainUnit}`
                        }`}
                    name={'amount'}
                    value={amount}
                    disabled={!balance?.bln}
                    type="number"
                    onChange={handleInputChange}
                />
                {balance && (
                    <CustomColumn
                        notRequired
                        child={
                            <Row justify="space-between">
                                <Col>
                                    <WithdrawalFeeInfo>
                                        <p>
                                            {balance?.out_min > 0 && !user?.ko_max && `${tPayment['payment@withdrawal-min-amount']}: ${displayAmountWithPrecision(
                                                balance?.out_min
                                            )}${mainUnit && ` ${mainUnit}`
                                                }`}
                                            {!balance?.out_min && user?.ko_max > 0 && `${tPayment['payment@withdrawal-max-amount']}: ${displayAmountWithPrecision(
                                                user?.ko_max
                                            )}${mainUnit && ` ${mainUnit}`
                                                }`}
                                            {balance?.out_min > 0 && user?.ko_max > 0 && `${tPayment['payment@withdrawal-amount-limit']
                                                }: ${displayAmountWithPrecision(
                                                    balance?.out_min
                                                )} ~ ${displayAmountWithPrecision(
                                                    user?.ko_max
                                                )}${mainUnit && ` ${mainUnit}`
                                                }`}
                                        </p>
                                        {/* {balance?.nofee_yn ===
                                            WITHDRAWAL_FEE_STATUS.NO_FEE && (
                                            <p>
                                                {
                                                    tPayment[
                                                        'payment@withdrawal-no-fee-msg'
                                                    ]
                                                }
                                            </p>
                                        )} */}
                                        {balance?.out_fee_per > 0 && (
                                            <p>{feeText}</p>
                                        )}
                                    </WithdrawalFeeInfo>
                                </Col>
                            </Row>
                        }
                    />
                )}

                <CustomColumn
                    child={
                        <Button
                            onClick={() => {
                                if (!IS_KH && !canSubmit) {
                                    setErrorAlert({
                                        show: true,
                                        title: tCommon['common@failed'],
                                        message:
                                            tError[
                                            'error@deposit_insufficient_amount'
                                            ],
                                    });
                                    return;
                                }

                                if (pixelCodes && pixelCodes.length > 0) {
                                    pixelCodes.forEach((code) => window.fbq('trackSingleCustom', code, 'WithdrawlSubmission'));
                                }

                                setShowConfirmModal(true);
                            }}
                        >
                            {tCommon['common@submit']}
                        </Button>
                    }
                    notRequired
                />
            </ColumnWrap>

            {displayPolicy && (
                <PolicyWrap>
                    <div dangerouslySetInnerHTML={{ __html: policy.content }} />
                </PolicyWrap>
            )}
            <BankAccountModal
                item={addBankModalInfo.item}
                show={addBankModalInfo.display}
                onToggleAddBankModal={onToggleAddBankModal}
                bankoutList={bankoutList}
                addBankaccount={addBankaccount}
                needFullName={true}
            />

            <ConfirmModal
                show={showConfirmModal}
                message={tPayment['payment@confirm-withdrawal']}
                children={
                    <WithdrawalFeeInfo>
                        <p className="text-center">{`${tPayment['payment@withdrawal-amount']}: ${amount} ${mainUnitRaw && ` ${mainUnitRaw}`
                            }`}</p>
                        {balance?.out_fee_per > 0 &&
                            < p className="text-center">{feeText}</p>
                        }
                    </WithdrawalFeeInfo>
                }
                onConfirm={doWithdrawal}
                onCancel={() => setShowConfirmModal(false)}
            />
            < AlertModal
                {...errorAlert}
                onConfirm={() => setErrorAlert({ ...INIT_ALERT })}
            />
        </PaymentPageWrap >
    );
}

const WithdrawalFeeInfo = styled.div`
    color: black;
    > p {
        margin: 0;
        margin-top: 5px;
        font-size: ${FONT_SIZE.ALERT_MODAL_TITLE};
        font-weight: 800;
    }
    .text-center {
        text-align: center;
    }
`;

