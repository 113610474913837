/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import AffiliateOverview from '@components/shared/AffiliateOverview';
import {
    Button,
    ColumnWrap,
    CustomColumn,
    PaymentPageWrap,
    PolicyWrap,
    PaymentOptionOutlineButton,
    PaymentImg,
} from '@components/shared/View';
import { Form, Input } from 'antd';
import { FaMobile } from 'react-icons/fa';

import { useGlobalProps } from '@hooks/useGlobalProps';
import ConfirmModal from '@components/shared/modals/ConfirmModal';
import AlertModal from '@components/shared/modals/AlertModal';
import { FONT_SIZE } from '@constants/numbers';
import styled from 'styled-components';
import { STATUS_CODE } from '@constants/api';
import { getDPolicy } from '@api/other';

import { Spin } from 'antd';
// import { CopyToClipboard } from 'react-copy-to-clipboard';
import { displayAmountWithPrecision } from '@utils/util';
import { getBankOutList } from '@api/other';
import { BANK_METAS_INFO } from '@constants/config'
import AmountColumn from '@components/shared/AmountColumn';
import MobileVerifyModal from '@components/shared/modals/MobileVerifyModal';
import { getUserInfo } from '@api/user';
import { getKEDepositV2 } from '@api/payment';

const INIT_INPUT_FILED = {
    accountName: '',
    amount: '',
    mobile: '',
};
const INIT_ALERT = {
    show: false,
    title: '',
    DepositKEPage,
};

const PHONE_DEFAULT_TELHD = '01';
const PHONE_DEFAULT_MIN = 0;
const PHONE_DEFAULT_MAX = 13;

export default function DepositKEPage() {
    const [inputField, setInputField] = useState({ ...INIT_INPUT_FILED });
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [alert, setAlert] = useState({ ...INIT_ALERT });
    const [policy, setPolicy] = useState(null);
    const {
        user,
        userData,
        depositAmountOptions,
        mainUnit,
        mainUnitRaw,
        secondUnitRaw,
        unitRate,
        getBalance,
        pixelCodes,
        depositStepperOptions
    } = useGlobalProps(); //, balance
    const { i18n, t } = useTranslation();
    const tCommon = t('common', { returnObjects: true });
    const tPayment = t('payment', { returnObjects: true });
    const tUser = t('user', { returnObjects: true });
    const tError = t('error', { returnObjects: true });

    // eslint-disable-next-line no-unused-vars
    // const [messageApi, contextHolder] = message.useMessage();

    const [isLoading, setIsLoading] = useState(false);

    const [paymentOption, setPaymentOption] = useState('');

    const [amountInfos, setAmountInfos] = useState([]);

    const [bankList, setBankList] = useState([]);

    const [showMobileVerifyModal, setShowMobileVerifyModal] = useState(false);

    const formRef = useRef(null);

    const onPaymentOptionClick = (e) => {
        setPaymentOption(e.target.name);
    };

    const maxAmount = user?.ki_max || 0;


    const getPolicy = async () => {
        const res = await getDPolicy(i18n.language);
        if (res && res[0]) setPolicy(res[0]);
        else setPolicy(null);
    };

    useEffect(() => {
        if (user?.ukey) getBalance();
    }, [user, getBalance]);

    useEffect(() => {
        getBankOutList().then((res) => {
            if (res?.rtn === STATUS_CODE.SUCCESS && res?.bank_list) {
                setBankList(res?.bank_list.map((e) => {
                    const [bankcode] = e;
                    return bankcode.trim();
                }));
            }
        });
        console.log(bankList)
        setPaymentOption('MPESA');
    }, []);


    const validatorPhone = (_rule, value) => {
        if (!value) return Promise.reject();
        const trimmedValue = value.trim();

        const validLen = !(
            trimmedValue.length < user.minln || trimmedValue.length > user.maxln
        );
        if (user?.telhd && user?.minln && user?.maxln) {
            const telhds = user?.telhd.split(',');
            let isValid = false;
            telhds.forEach((e) => {
                if (!isValid && trimmedValue.startsWith(e) && validLen) {
                    isValid = true;
                }
            });
            if (!!isValid) return Promise.resolve();
        }

        return Promise.reject();
    };

    const hintPhone = tUser['hint@phone']
        ? tUser['hint@phone']
            .replace('{telhd}', user?.telhd || PHONE_DEFAULT_TELHD)
            .replace('{minln}', user?.minln || PHONE_DEFAULT_MIN)
            .replace('{maxln}', user?.maxln || PHONE_DEFAULT_MAX)
        : '';

    const handleInputChange = (e) => {
        const target = e.target;
        const name = target.name;
        let val = target.value;
        if (name === 'amount' && maxAmount > 0) {
            if (val > maxAmount) val = maxAmount;
        }

        setInputField((prev) => {
            return { ...prev, [name]: val };
        });
    };

    const checkError = () => {
        let message = null;

        if (!inputField.amount) {
            message = tError['error@must_fill_amount'];
        } else if (!inputField.mobile) {
            message = tError['error@no-fill-phone'];
        }

        if (!paymentOption) {
            message = tError['error@deposit_option_required'];
        }

        try {
            if (
                (!depositAmountOptions || !depositAmountOptions.display) &&
                parseInt(inputField.amount) < userData?.bank_in_min
            ) {
                message = tError['error@deposit_insufficient_amount'];
            }
        } catch (e) {
            message = tError['error@deposit_insufficient_amount'];
        }

        return message;
    };

    useEffect(() => {
        if (Object.keys(depositAmountOptions).length > 0) {
            const infos = [];
            if (depositAmountOptions.amounts) {
                for (const amount of depositAmountOptions.amounts) {
                    const info = {
                        amount,
                        currency: depositAmountOptions.currency,
                        pointUnit: depositAmountOptions.pointUnit,
                        points: depositAmountOptions.rate
                            ? amount * parseFloat(depositAmountOptions.rate)
                            : 0,
                        rate: depositAmountOptions.rate
                            ? parseFloat(depositAmountOptions.rate)
                            : 0,
                    };
                    infos.push(info);
                }
            }

            setAmountInfos(infos);
        }
    }, [depositAmountOptions]);

    useEffect(() => {
        if (userData?.user_name)
            setInputField({ ...inputField, accountName: userData.user_name });

        // if (user && user.last_tel) {
        //     setInputField({ ...inputField, mobile: user.last_tel });
        //     formRef.current.setFieldsValue({
        //         mobile: user.last_tel
        //     });
        // } else 
        if (userData?.tel) {
            setInputField({ ...inputField, mobile: userData.tel });
            formRef.current.setFieldsValue({
                mobile: userData.tel
            });
        }

    }, [userData, user]);


    useEffect(() => {
        getPolicy();
    }, [i18n.language]);

    const doDeposit = async () => {
        setIsLoading(true);
        setShowConfirmModal(false);

        try {

            let ukey = user.ukey;

            const userInfoRes = await getUserInfo({ dvc: user?.device_id });


            if (userInfoRes.ukey) {
                ukey = userInfoRes.ukey;
            }

            const req = {
                ukey: ukey,
                state: 1,
                val: inputField.amount,
                tel: inputField.mobile,
                // acc: user.acc,
                // bank: paymentOption
            }

            // const url = GetKEDepositUrl(req);

            const res = await getKEDepositV2(req);

            setIsLoading(false);


            if (!res || typeof res !== 'object') {

                setAlert({
                    show: true,
                    title: tCommon['common@failed'],
                    // message: parseErrorRes(tError, res),
                    message: tError['error@server_error'],
                });
                setIsLoading(false);

                return;
            }

            if (res['ret code'] !== "0" && res['ret code'] !== "00") {
                setAlert({
                    show: true,
                    title: tCommon['common@failed'],
                    message: res['ret msg'],
                });
                setIsLoading(false);

                return;
            }

            let data;

            console.log(' RES _>  ', res)

            if (res && res.data) {
                try {
                    data = JSON.parse(res.data);
                } catch (error) {
                    data = {
                        state: false,
                        info: 'cannot parse RES',
                    };
                }
            } else {
                data = {
                    state: false,
                    info: tError['error@cannot_get_bank_info'],
                };
            }

            // const url = res.url;
            console.log(' data -->  ', data)


            // const mepesaRes = await postKEMepesaUrl('https://mpesa.igp.gs/payment/v1/deposit', data);

            // console.log(' mepesa res -> ', mepesaRes)


            // if (data.status === 10000) {
            //     if (data.result && data.result.payurl) {
            //         window.location.href = data.result.payurl
            //     }

            //     //error@deposit_insufficient_amount
            // } else if (data.status === 21033) {
            //     setAlert({
            //         show: true,
            //         title: tCommon['common@failed'],
            //         message: `${tCommon['error@deposit_insufficient_amount']}(${data.status})`,
            //     });
            // } else {
            //     setAlert({
            //         show: true,
            //         title: tCommon['common@failed'],
            //         message: `${tError['error@deposit']}(${data.status})`,
            //     });
            // }

        } catch (error) {
            setIsLoading(false);

            setAlert({
                show: true,
                title: tCommon['common@failed'],
                // message: parseErrorRes(tError, res),
                message: tError['error@server_error'],
            });
        }



        // if (url) {
        //     window.location.href = url;
        //     messageApi.open({
        //         type: 'success',
        //         content: tPayment['payment@redirecting'],
        //     });
        // }


        setInputField({ ...INIT_INPUT_FILED, mobile: inputField.mobile });

    };

    const handleSelectAmount = (val) => {
        setInputField((prev) => {
            if (depositStepperOptions && depositStepperOptions.display && parseFloat(val) > 0 && prev.amount !== '') {
                if (maxAmount > 0 && (parseFloat(prev.amount) + parseFloat(val) > maxAmount)) {
                    return { ...prev, amount: maxAmount };
                }
                return { ...prev, amount: parseFloat(prev.amount) + parseFloat(val) };
            } else {
                return { ...prev, amount: val === 0 ? '' : val };
            }
        });
    };


    // useEffect(() => formRef.resetFields(), [props.initialValues]);


    const displayPolicy = policy && policy.content?.length > 0;

    return (
        <PaymentPageWrap>

            <AffiliateOverview />
            <ColumnWrap className="mt-20 mb-1 pl-3" alignItems="start">

                <>
                    <CustomColumn
                        title={tPayment['payment@deposit-method']}
                        child={
                            <>
                                <div style={{ display: 'flex' }}>
                                    {bankList.map((code) => {
                                        const bankInfo = BANK_METAS_INFO[code];
                                        if (!bankInfo) return null;
                                        return <PaymentOptionOutlineButton
                                            key={code}
                                            onClick={onPaymentOptionClick}
                                            name={bankInfo.name}
                                            isSelected={
                                                paymentOption === bankInfo.name
                                            }
                                        >
                                            <PaymentImg
                                                width={bankInfo.imageWidth ? bankInfo.imageWidth : '100px'}
                                                src={bankInfo.imageUrl}
                                                alt={bankInfo.name}
                                                name={bankInfo.name}
                                            />
                                        </PaymentOptionOutlineButton>
                                    })}
                                </div>
                            </>
                        }
                    />
                    <CustomColumn
                        title={tUser['user@phone']}
                        child={
                            <Form ref={formRef}>
                                <Form.Item
                                    name={'mobile'}
                                    rules={[
                                        {
                                            required: true,
                                            message: hintPhone,
                                            min: user?.minln || PHONE_DEFAULT_MIN,
                                            max: user?.maxln || PHONE_DEFAULT_MAX,
                                            whitespace: false,
                                            validator: validatorPhone,
                                        },
                                    ]}
                                    // help={hintPhone}
                                    initialValue={inputField.mobile}
                                    className={`register-form-mobile`}
                                >
                                    <Input
                                        name={'mobile'}
                                        type={
                                            'text'
                                        }
                                        addonBefore={<FaMobile />}
                                        maxLength={
                                            ''
                                        }
                                        value={inputField.mobile}
                                        onChange={handleInputChange}
                                        readOnly={true}
                                    />
                                </Form.Item>
                            </Form>
                        }
                    />

                    {/* <Column
                        title={tUser['user@phone']}
                        name={'mobile'}
                        value={inputField.mobile}
                        onChange={handleInputChange}
                    /> */}

                    <AmountColumn
                        title={tPayment['payment@deposit-amount']}
                        mainUnit={mainUnit}
                        displayAmountOptions={depositAmountOptions && depositAmountOptions.display}
                        amount={inputField.amount}
                        onAmountChange={handleInputChange}
                        onSelecteAmount={handleSelectAmount}
                        amountInfos={amountInfos}
                        displayStepper={depositStepperOptions && depositStepperOptions.display}

                    />


                </>

                <CustomColumn
                    notRequired
                    child={
                        <DepositInfo>
                            <p>
                                {userData?.bank_in_min > 0 && !maxAmount && `${tPayment['payment@deposit-min-amount']}: ${displayAmountWithPrecision(
                                    userData?.bank_in_min
                                )}${mainUnit && ` ${mainUnit}`
                                    }`}
                                {!userData?.bank_in_min && maxAmount > 0 && `${tPayment['payment@deposit-max-amount']}: ${displayAmountWithPrecision(
                                    maxAmount
                                )}${mainUnit && ` ${mainUnit}`
                                    }`}
                                {userData?.bank_in_min > 0 && maxAmount > 0 && `${tPayment['payment@deposit-amount-limit']
                                    }: ${displayAmountWithPrecision(
                                        userData?.bank_in_min
                                    )} ~ ${displayAmountWithPrecision(
                                        maxAmount
                                    )}${mainUnit && ` ${mainUnit}`
                                    }`}
                            </p>
                        </DepositInfo>
                    }
                />

                <CustomColumn
                    child={
                        <SpinContainer>
                            {isLoading ? (
                                <Spin
                                    style={{
                                        top: '25%',
                                        left: '25%',
                                        position: 'absolute',
                                    }}
                                />
                            ) : (
                                <Button
                                    onClick={() => {
                                        const message = checkError();
                                        if (message) {
                                            setAlert({
                                                show: true,
                                                title: tCommon['common@failed'],
                                                message: message,
                                            });
                                            return;
                                        }

                                        if (pixelCodes && pixelCodes.length > 0) {
                                            pixelCodes.forEach((code) => window.fbq('trackSingleCustom', code, 'DepositSubmission'));
                                        }

                                        setShowConfirmModal(true);
                                    }}
                                >
                                    {tCommon['common@submit']}
                                </Button>
                            )}
                        </SpinContainer>
                    }
                    notRequired
                />
            </ColumnWrap>

            {displayPolicy && (
                <PolicyWrap>
                    <div dangerouslySetInnerHTML={{ __html: policy.content }} />
                </PolicyWrap>
            )}

            <ConfirmModal
                show={showConfirmModal}
                title={tPayment['payment@confirm-deposit']}
                children={
                    <DepositInfo>
                        <p className="text-center">{`${tPayment['payment@deposit-amount']}: ${inputField.amount}${mainUnitRaw && ` ${mainUnitRaw}`
                            }${secondUnitRaw && unitRate && ` (${inputField.amount * parseFloat(unitRate)} ${secondUnitRaw})`
                            }`}</p>
                    </DepositInfo>
                }
                onConfirm={doDeposit}
                onCancel={() => setShowConfirmModal(false)}
            />
            <MobileVerifyModal
                show={showMobileVerifyModal}
                message={tPayment['payment@change-mobile']}
                onConfirm={(phone) => {
                    setInputField({ ...inputField, mobile: phone });
                    setShowMobileVerifyModal(false)
                }}
                onCancel={() => setShowMobileVerifyModal(false)}
            />
            <AlertModal
                {...alert}
                onConfirm={() => setAlert({ ...INIT_ALERT })}
            />
        </PaymentPageWrap>
    );
}

// const MobileInfo = styled.div`

//     display: flex;
//     align-items: center;
//     > span {
//         color: black;   
//         margin-right: 8px;
//         font-size: ${FONT_SIZE.NORMAL};
//         font-weight: 800;
//     }
// `;

const DepositInfo = styled.div`
    color: black;
    > p {
        margin: 0;
        margin-top: 5px;
        font-size: ${FONT_SIZE.ALERT_MODAL_TITLE};
        font-weight: 800;
    }
    .text-center {
        text-align: center;
    }
`;

// const BankInfo = styled.div`
//     color: black;
//     margin-top: 16px;
//     display: flex;
//     align-items: center;
//     .copy-icon {
//         cursor: pointer;
//         mask-image: url('/images/shared/copy.svg');
//         mask-repeat: no-repeat;
//         mask-position: center;
//         mask-size: contain;
//         min-width: 18px;
//         min-height: 18px;
//         background: black;
//     }
// `;

const SpinContainer = styled.div`
    textalign: center;
    position: relative;
    width: 100px;
    height: 40px;
`;
