import { APIS } from '@constants/api';
import { generalFetch } from '@utils/generalFetch';

export const getAllowUserGrading = () => {
    return generalFetch(`${APIS.SYSTEM.GET_ALLOW_USER_GRADING}`, 'GET');
};
export const getDisplayProfilePic = () => {
    return generalFetch(`${APIS.SYSTEM.GET_DISPLAY_PROFILE_PIC}`, 'GET');
};
export const getCurrencyDecimalPlace = () => {
    return generalFetch(`${APIS.SYSTEM.GET_CURRENCY_DECIMAL_PLACE}`, 'GET');
};

export const getDisplayDepositAmountOptions = () => {
    return generalFetch(
        `${APIS.SYSTEM.GET_DISPLAY_DEPOSIT_AMOUNT_OPTIONS}`,
        'GET'
    );
};
export const getDepositAmountOptions = () => {
    return generalFetch(`${APIS.SYSTEM.GET_DEPOSIT_AMOUNT_OPTIONS}`, 'GET');
};

export const getDisplayWithdrawAmountOptions = () => {
    return generalFetch(
        `${APIS.SYSTEM.GET_DISPLAY_WITHDRAW_AMOUNT_OPTIONS}`,
        'GET'
    );
};
export const getWithdrawAmountOptions = () => {
    return generalFetch(`${APIS.SYSTEM.GET_WITHDRAW_AMOUNT_OPTIONS}`, 'GET');
};

export const getIsLiveChatOn = () => {
    return generalFetch(`${APIS.SYSTEM.GET_IS_LIVE_CHAT_ON}`, 'GET');
};

export const getIsTelegramOn = () => {
    return generalFetch(`${APIS.SYSTEM.GET_IS_TELEGRAM_ON}`, 'GET');
};

export const getTelegramAccount = () => {
    return generalFetch(`${APIS.SYSTEM.GET_TELEGRAM_ACCOUNT}`, 'GET');
};

export const getIsWhatsappOn = () => {
    return generalFetch(`${APIS.SYSTEM.GET_IS_WHATSAPP_ON}`, 'GET');
};

export const getWhatsappAccount = () => {
    return generalFetch(`${APIS.SYSTEM.GET_WHATSAPP_ACCOUNT}`, 'GET');
};

export const getInstallPromptNews = () => {
    return generalFetch(`${APIS.SYSTEM.GET_IS_INSTALLPROMPT_NEWS}`, 'GET');
};

export const getMenuItemKeys = () => {
    return generalFetch(`${APIS.SYSTEM.GET_MENU_ITEM_KEYS}`, 'GET');
};

export const getForgotPasswordEnabled = () => {
    return generalFetch(`${APIS.SYSTEM.GET_IS_FORGOT_PASSWORD_ENABLED}`, 'GET');
};

export const getShareDialogLayout = () => {
    return generalFetch(`${APIS.SYSTEM.GET_SHARE_DIALOG_LAYOUT}`, 'GET');
};

export const getDefaultLanguage = () => {
    return generalFetch(`${APIS.SYSTEM.GET_DEFAULT_LANGUAGE}`, 'GET');
};

export const getDepositIsSemiAuto = () => {
    return generalFetch(`${APIS.SYSTEM.GET_DEPOSIT_IS_SEMI_AUTO}`, 'GET');
};

export const getDepositQRCodeOptions = () => {
    return generalFetch(`${APIS.SYSTEM.GET_DEPOSIT_QRCODE_OPTIONS}`, 'GET');
};


export const getPixelCodes = () => {
    return generalFetch(`${APIS.SYSTEM.GET_PIXELCODES}`, 'GET');
};

export const getPixelCodeInfos = () => {
    return generalFetch(`${APIS.SYSTEM.GET_PIXELCODE_INFOS}`, 'GET');
};



export const getRegisterFlowType = () => {
    return generalFetch(`${APIS.SYSTEM.GET_REGISTER_FLOW_TYPE}`, 'GET');
};

export const getForgotPasswordFlowType = () => {
    return generalFetch(`${APIS.SYSTEM.GET_FORGOT_PASSWORD_FLOW_TYPE}`, 'GET');
};


export const getDepositStepperOptions = () => {
    return generalFetch(`${APIS.SYSTEM.GET_DEPOSIT_STEPPER_OPTIONS}`, 'GET');
};

export const getWithdrawStepperOptions = () => {
    return generalFetch(`${APIS.SYSTEM.GET_WITHDRAW_STEPPER_OPTIONS}`, 'GET');
};

export const getAdPageAnnouncement = () => {
    return generalFetch(`${APIS.SYSTEM.GET_AD_PAGE_ANNOUCEMENT}`, 'GET');
};


