import React from 'react';
import styled from 'styled-components';
import { Table, ConfigProvider } from 'antd';
import { FONT_SIZE } from '@constants/numbers';
import { Button } from '@components/shared/View';
import { covertResArrayIntoObjecMap } from '@utils/util';
import useIsMobile from '@hooks/useIsMobile';
import { useTranslation } from 'react-i18next';

const USER_BANK_ID = 'id';
const IS_BOUND_TEXT = 'isBound';
const BANKCODE_TEXT = 'bankcode';
const BANKNAME_TEXT = 'bankname';
const BANKACCOUNT_TEXT = 'account';
const OWNER_TEXT = 'name';
const TC_LENGTH = 'tcLength';


export function parseBankRes(bankaccounts) {
    return covertResArrayIntoObjecMap(bankaccounts, {
        [USER_BANK_ID]: 0,
        [BANKCODE_TEXT]: 1,
        [BANKNAME_TEXT]: 2,
        [OWNER_TEXT]: 3,
        [BANKACCOUNT_TEXT]: 4,
        [IS_BOUND_TEXT]: 5,
        [TC_LENGTH]: 6
    });
}

export default function SingleBankTable(props) {
    const { dataSource, tBank, onChangeAccount, emptyView, onCreateAccount, displayFullName } =
        props;
    const isMobile = useIsMobile();
    const { t } = useTranslation();
    const tCommon = t('common', { returnObjects: true });

    const Empty = () => <div>{emptyView || tCommon['table@no-data']}</div>;

    const renderPCAccountColumn = (text, item) => {
        return (
            <div className="bankacc-pc-account-column">
                <span>{item[BANKNAME_TEXT]}</span>
                <span>{item[BANKACCOUNT_TEXT]}</span>
                {displayFullName && <span style={{ border: '1px solid black', padding: '4px 16px', borderRadius: '20px' }}>{` ${item[OWNER_TEXT]} `}</span>}
            </div>
        );
    };
    const renderMobileAccountColumn = (text, item) => {
        return (
            <div className="bankacc-pc-account-column mobile-acc-column">
                <span>{item[BANKNAME_TEXT]}</span>
                <span>{item[BANKACCOUNT_TEXT]}</span>
                {displayFullName && <span style={{ border: '1px solid black', padding: '4px 16px', borderRadius: '20px' }}>{` ${item[OWNER_TEXT]} `}</span>}
            </div>
        );
    };
    const renderBoundColumn = (text, item) => {
        if (item?.isBound)
            return (
                <div className="bankaccount-primary-text">
                    <Button onClick={() => onChangeAccount?.(item)}>
                        {tBank['bank@change']}
                    </Button>
                </div>
            );
        else return <></>;
    };

    const columns = [
        {
            title: tBank['bank@bank-account'],
            dataIndex: 'account',
            key: 'account',
            render: renderPCAccountColumn,
        },
        {
            title: tBank['bank@action'],
            dataIndex: 'bound',
            key: 'bound',
            render: renderBoundColumn,
        },
    ];
    const mobileColumns = [
        {
            title: tBank['bank@bank-account'],
            key: 'account',
            render: renderMobileAccountColumn,
        },
        {
            title: tBank['bank@action'],
            dataIndex: 'bound',
            key: 'bound',
            render: renderBoundColumn,
        },
    ];

    const emptyColumns = [
        {
            title: tBank['bank@bank-account'],
            key: 'account',
            render: () => (
                <div>
                    <span style={{ color: 'lightgray' }}>
                        {tBank['bank@no-account']}
                    </span>
                </div>
            ),
        },
        {
            title: tBank['bank@action'],
            dataIndex: 'bound',
            key: 'bound',
            render: () => (
                <div className="bankaccount-primary-text">
                    <Button onClick={() => onCreateAccount?.()}>
                        {tBank['bank@add']}
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <TableComponentWrap>
            <ConfigProvider renderEmpty={Empty}>
                <Table
                    className="bank-table"
                    rowKey={(r) => `${r.bankcode}+${r.account}`}
                    bordered
                    dataSource={
                        dataSource && dataSource.length > 0 ? dataSource : [{}]
                    }
                    columns={
                        dataSource && dataSource.length > 0
                            ? isMobile
                                ? mobileColumns
                                : columns
                            : emptyColumns
                    }
                    pagination={false}
                />
            </ConfigProvider>
        </TableComponentWrap>
    );
}

const TableComponentWrap = styled.div`
    width: 100%;

    .bank-table {
        display: block;
    }
    .bank-table-md {
        display: block;
        p {
            margin: 4px 0;
        }
    }
    .bankacc-pc-account-column {
        display: flex;
        align-items: center;
        gap: 5px;
        > span:first-child {
            background: rgb(33, 37, 41);
            color: white;
            padding: 2px 5px;
            border-radius: 25px;
        }
    }
    .mobile-acc-column {
        flex-direction: column;
        align-items: flex-start;
    }
    .bankaccount-primary-text {
        font-size: 16px;
        font-weight: 900;
    }
    .ant-table {
        font-size: ${FONT_SIZE.NORMAL};
    }
`;
